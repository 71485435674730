/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  /* background: #2c3e50;
  background: -webkit-linear-gradient(to right, #3498db, #2c3e50);
  background: linear-gradient(to right, #3498db, #2c3e50); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
/*
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

/* *{
    font-family: cursive;
} */

.main-container-box{height: 100vh; padding-left: 0%; width: 100vw;}

/*====== Dashboard page css Start ==========*/
.HomeMainDiv{width: 100%;height: min-content; padding-top: 7vh;}
.LeftSideDiv {background: rgba(28, 103, 104, 0.05);float: left;width: 320px;padding-left: 38px;position: fixed;height: calc(100vh - 97px);}
.LeftSideDiv .leftbottomicon {margin-top: 40px;float: left;width: 100%;margin-bottom: 20px;}
.LeftSideDiv .leftbottomicon button.btn.upgradebtn {background: #1C6768;box-shadow: 1px 4px 10px rgb(31 100 255 / 25%);border-radius: 4px;width: 230px;margin: 0 auto;display: block;margin-top: 30px;font-family: 'Poppins'; font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;text-align: center;color: #FFFFFF;height: 50px;}

.loader{
  cursor: progress;
}
/* select dropdown clear button code  */
.input-wrapper {
  position: relative;
  display: inline-block;
}

.clear-icon {
  position: absolute;
  right: 8px; /* Adjust this value to control the position of the icon */
  top: 50%; /* Adjust this value to vertically center the icon */
  transform: translateY(-50%);
  cursor: pointer;
}

/* company table styles starts */
.table thead tr.tableHeading th{
  text-align: center;
}
.form-control.ng-touched.ng-invalid{
  border:2px solid red;
}
